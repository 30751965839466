import clsx from "clsx"
import React from "react"
import { Controller } from "react-hook-form"
import PhoneNumberInput from "react-phone-input-2"
import "react-phone-input-2/lib/plain.css"

const PhoneInput = ({ control, errors, name, className }) => {
  const [country, setCountry] = React.useState("ae")
  // set country code based on cuurent location
  React.useEffect(() => {
    fetch("https://freeipapi.com/api/json/")
      .then(res => res.json())
      .then(data => {
        setCountry(data?.countryCode?.toLowerCase())
      })
  }, [])

  return (
    <div className={clsx("input-box phone-input left-field", className)}>
      <label htmlFor={name} className="form-label">
        Phone:*
      </label>
      <Controller
        control={control}
        name={name}
        render={({ field: { ref, ...field } }) => (
          <PhoneNumberInput
            {...field}
            inputProps={{
              ref,
              required: true,
            }}
            country={country}
            enableSearch
            inputClass="input-field input-phone"
            dropdownClass="input-phone-dropdown"
            buttonClass="input-field"
          />
        )}
      />
      {errors?.[name] && <p className="error-msg">{errors?.[name]?.message}</p>}
    </div>
  )
}

export default PhoneInput
