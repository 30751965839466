import loadable from "@loadable/component"
import {
  UserObjectStoreProvider,
  useAuthState,
  useUserObjectState,
} from "@myaccount/website-service"
import { isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
import { isMobileOnly } from "react-device-detect"
import { useQuery } from "react-query"
import { getWhatsAppURL } from "../common/misc"
import Footer from "../components/Footer/Footer"
import OffplanBanner from "../components/OffplanBanner/OffplanBanner"
import SEO from "../components/Seo/seo"
import { phoneNumber, whatsAppNo } from "../components/common/site/utils"
import useHasScrolled from "../hooks/useHasScrolled"
import BrandLogo from "../images/icons/logo.svg"
import NotFoundPage from "../pages/404"

const EnquireModal = loadable(() =>
  import("../components/EnquireModal/EnquireModal")
)
const OffplanSticky = loadable(() =>
  import("../components/OffplanSticky/OffplanSticky")
)
const OffplanDescription = loadable(() =>
  import("../components/OffplanDescription/OffplanDescription")
)
const OffplanAmenities = loadable(() =>
  import("../components/OffplanAmenities/OffplanAmenities")
)
const OffplanGallery = loadable(() =>
  import("../components/OffplanGallery/OffplanGallery")
)
const OffplanUnitTypes = loadable(() =>
  import("../components/OffplanUnitTypes/OffplanUnitTypes")
)
const OffplanFloor = loadable(() =>
  import("../components/OffplanFloor/OffplanFloor")
)
const OffplanBrochure = loadable(() =>
  import("../components/OffplanBrochure/OffplanBrochure")
)
const OffplanPayment = loadable(() =>
  import("../components/OffplanPayment/OffplanPayment")
)
const PropertyDetailsMap = loadable(() =>
  import("../components/PropertyDetailsMap/PropertyDetailsMap")
)
const OffplanInvestors = loadable(() =>
  import("../components/OffplanInvestors/OffplanInvestors")
)
const OffplanRegister = loadable(() =>
  import("../components/OffplanRegister/OffplanRegister")
)
const OffplanDevelopments = loadable(() =>
  import("../components/OffplanDevelopments/OffplanDevelopments")
)
// import EnquireModal from '../components/EnquireModal/EnquireModal';
// import OffplanSticky from "../components/OffplanSticky/OffplanSticky";
// import OffplanDescription from "../components/OffplanDescription/OffplanDescription";
// import OffplanAmenities from "../components/OffplanAmenities/OffplanAmenities";
// import OffplanGallery from "../components/OffplanGallery/OffplanGallery";
// import OffplanUnitTypes from "../components/OffplanUnitTypes/OffplanUnitTypes";
// import OffplanFloor from "../components/OffplanFloor/OffplanFloor";
// import OffplanBrochure from "../components/OffplanBrochure/OffplanBrochure";
// import OffplanPayment from "../components/OffplanPayment/OffplanPayment";
// import PropertyDetailsMap from "../components/PropertyDetailsMap/PropertyDetailsMap";
// import OffplanInvestors from "../components/OffplanInvestors/OffplanInvestors";
// import OffplanRegister from "../components/OffplanRegister/OffplanRegister";
// import OffplanDevelopments from "../components/OffplanDevelopments/OffplanDevelopments";

const getOffplan = async id => {
  const url = `${process.env.GATSBY_STRAPI_SRC}/properties?publish_eq=true&id=${id}`
  const response = await fetch(url)

  return response.json()
}

const OffplanDetailsTemplate = () => {
  const scrolled = useHasScrolled()
  const { state, services } = useUserObjectState()
  const { services: authServices } = useAuthState()
  const isAuthenticated = authServices.isAuthenticated()
  const [modalShow, setModalShow] = useState(false)

  //
  const pageurl = typeof window !== "undefined" ? window.location.href : ""
  let myid_filt = pageurl.split("?")
  let mypageurl = myid_filt[0]
  let property_slug = mypageurl.split("-")
  let id = property_slug[property_slug.length - 1]
  id = id.replace("/", "")

  useEffect(() => {
    if (isAuthenticated) {
      // on page load get the users saved objects
      services.getUserobject({ type: "property", state: true })
    }
  }, [])

  const { data, isLoading, isError } = useQuery({
    queryFn: () => getOffplan(id),
    queryKey: ["offplan", id],
    refetchOnWindowFocus: false,
  })

  const property = data?.[0]

  if (isLoading) {
    return (
      <section className={"loader-wrapper"}>
        <div id="loader-wrapper">
          <div id="loader" class="new-loader">
            <img className="logo-white" src={BrandLogo} alt="logo" />
          </div>
        </div>
      </section>
    )
  } else if (isError || isEmpty(property)) {
    return <NotFoundPage />
  }

  const building =
    property.extra?.building_type?.length > 1
      ? property.extra?.building_type?.join(" and ")
      : property.extra?.building_type[0]

  const buildingType = building ? building : "property"

  const searchaction =
    property?.search_type === "sales" ? "for sale" : "for rent"

  const metatile = `Off plan ${buildingType} ${searchaction} with ${
    property.bedroom
  } bedrooms in ${
    property.display_address
  } at £${property.price.toLocaleString()}`

  const metadesc = `Know the details of off plan ${buildingType} ${searchaction} with ${
    property.bedroom
  } bedrooms in ${
    property.display_address
  } at £${property.price.toLocaleString()}. Book a viewing to get assistance in finding the right ${buildingType} for you.`

  return (
    <>
      <SEO
        image={property.images && property.images[0]?.url}
        title={metatile}
        description={metadesc}
      />

      <section className="offplan-details">
        <OffplanBanner propertyData={property} />
        <OffplanSticky propertyData={property} />
        <OffplanDescription propertyData={property} />
        <OffplanAmenities propertyData={property} />
        <OffplanGallery propertyData={property} />
        <OffplanUnitTypes propertyData={property} />
        <OffplanFloor propertyData={property} />
        <OffplanBrochure propertyData={property} />
        <OffplanPayment propertyData={property} />
        {/* <OffplanArea propertyData={data} /> */}
        {property?.latitude && property?.longitude && (
          <PropertyDetailsMap
            lat={property?.latitude}
            lng={property?.longitude}
            offplanMap={"off-plan-map"}
          />
        )}
        <OffplanInvestors propertyData={property} />
        {/* <OffplanCalculator price={data?.price} /> */}
        <OffplanRegister propertyData={property} />
        <OffplanDevelopments propertyData={property} />
        {/* <FAQ module={"contact"} /> */}
        <Footer layout="off-plan" />
        {scrolled && (
          <div className="property-btn-wrapper-fixed d-lg-none d-flex justify-content-between">
            <button
              className="btn btn-black"
              onClick={() => setModalShow(true)}
            >
              <i className="icon icon-property-envelope"></i>{" "}
              <span className="enq-mobile">Enquire</span>
            </button>
            <a href={`tel:${phoneNumber}`} className="btn btn-white">
              <i className="icon icon-property-phone"></i>
              {isMobileOnly && <span>Call</span>}{" "}
              <span className="d-md-flex d-none">{phoneNumber}</span>
            </a>
            <a
              href={getWhatsAppURL(whatsAppNo)}
              target={"_blank"}
              className="btn btn-white"
            >
              <i className="icon icon-property-whatsapp"></i>{" "}
              <span className="whatspp-mobile">Whatsapp</span>
            </a>
          </div>
        )}
      </section>

      <EnquireModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  )
}

const OffplanDetails = props => {
  return (
    <UserObjectStoreProvider>
      <OffplanDetailsTemplate {...props} />
    </UserObjectStoreProvider>
  )
}

export default OffplanDetails
