import { zodResolver } from "@hookform/resolvers/zod"
import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { scrollToElement, SendMail } from "../common/site/utils"
import { postFormData } from "./api/Api"
import Recaptcha from "./elements/NewRecaptcha"
import { registerInterestSchema } from "./schemas"
import PhoneInput from "./elements/PhoneInput"

const FORM_CONFIG = {
  form_name: "Download brochure",
  email_temp_user: "download_brochure_user",
  email_temp_admin: "download_brochure_admin",
  email_subject_user: "Thank you for your interest",
  email_subject_admin: "Download Brochure",
  event_tracking: "download-brochure",
}

const DownloadBrochureForm = ({ file_url }) => {
  const recaptchaRef = useRef()
  const [showSuccessMsg, setShowSuccessMsg] = useState(false)

  const { register, watch, formState, handleSubmit, reset, control } = useForm({
    resolver: zodResolver(registerInterestSchema),
  })
  const { errors } = formState

  const onSubmit = async data => {
    const token = await recaptchaRef.current.executeAsync()
    // const token = "await recaptchaRef.current.executeAsync()"
    if (token) {
      let postData = { ...data, ...FORM_CONFIG }

      postData.file_url = file_url

      postData.property_url =
        process.env.GATSBY_SITE_URL + window.location.pathname

      recaptchaRef.current.reset()
      postFormData(postData).then(async apiRes => {
        await SendMail(postData)
        // if(apiRes.success){
        //    handlePdf()

        // }

        // tracking event
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "formSubmit",
          formType: "form-" + FORM_CONFIG.event_tracking,
          formId: "form-" + FORM_CONFIG.event_tracking,
          formName: FORM_CONFIG.form_name,
          formLabel: FORM_CONFIG.form_name,
        })
      })
    }
  }

  const handlePdf = () => {
    if (file_url) {
      setTimeout(() => {
        window.open(file_url)
      }, 2000)
    }
  }

  useEffect(() => {
    // reset form and show success after onSubmit is run
    if (formState.isSubmitSuccessful) {
      reset()
      setShowSuccessMsg(true)
      const element = document.getElementById("form-success-msg")
      element && scrollToElement(element)
      // setTimeout(() => setShowSuccessMsg(false), 10000)
    }
  }, [formState, reset])

  return (
    <form
      className="enquiry-form "
      name="DownloadBrochure"
      onSubmit={handleSubmit(onSubmit)}
    >
      {showSuccessMsg && (
        <>
          <p className="success-msg brochure" id="form-success-msg">
            Thank you for your interest. You can download the Brochure by
            clicking on the button below.
          </p>
          <a className="download-brochure-link" href={file_url} target="_blank">
            Download Brochure
          </a>
        </>
      )}
      {!showSuccessMsg && (
        <>
          <div className="input-box">
            <label htmlFor="name">Full Name: *</label>
            <input type="text" placeholder="Enter Name" {...register("name")} />
            {errors?.name && (
              <p className="error-msg">{errors?.name.message}</p>
            )}
          </div>
          <div className="enquiry-form-row">
            <PhoneInput control={control} errors={errors} name="telephone" />
            <div className="input-box">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                placeholder="Enter email"
                {...register("email")}
              />
              {errors?.email && (
                <p className="error-msg">{errors?.email.message}</p>
              )}
            </div>
          </div>
          <button type="submit" className="btn btn-black">
            submit details
          </button>
          <div className="terms">
            <p>
              By clicking Submit you agree to our{" "}
              <Link to="/terms-and-conditions">Terms</Link> and{" "}
              <Link to="/privacy-policy">Privacy Policy</Link>.
            </p>
            <p className="required">* required field</p>
          </div>
          <div className="d-none">
            <Recaptcha recaptchaRef={recaptchaRef} />
          </div>
        </>
      )}
      {/* <pre>{JSON.stringify(watch(), null, 2)}</pre> */}
    </form>
  )
}

export default DownloadBrochureForm
